<div class="photo" [style.backgroundImage]="domSanitizer.bypassSecurityTrustStyle('url(' + src + ')')" (click)="$event.stopPropagation(); fullscreen.emit()">
  <div class="delete-button" (click)="$event.stopPropagation(); delete.emit()" *ngIf="editable">
    <mat-icon>delete</mat-icon>
  </div>

  <a *ngIf="downloadable" [attr.href]="src" download="" (click)="$event.stopPropagation()">
    <mat-icon>download</mat-icon>
  </a>

  <div class="progress" *ngIf="progress >= 0 && progress <= 100" [attr.progress]="progress" [style.width]="progress + '%'"></div>
  <div class="green-check" *ngIf="progress === 100">
    <mat-icon>check_circle</mat-icon>
  </div>
</div>
