import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {

  public user_role: string = "";

  constructor() {
    let role = localStorage.getItem("user_role");
    if(role){
      this.user_role = role;
    }
  }
}
