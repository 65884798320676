import { Injectable } from '@angular/core';
import {Field} from './interfaces/field';
// import {type} from "os";
import * as moment from 'moment';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  is_ios() {
    return navigator.userAgent.match(/ipad|iphone/i);
  }

  format_title_for_export_view(export_obj, date = true) {
    let title = '';

    let sub = this.find_field(export_obj.fields, 'variety_multiple');
    if (!sub) {
      sub = this.find_field(export_obj.fields, 'variety');
    }

    let label = '';
    label = this.find_field(export_obj.fields, 'label');
    if (label) { label = ' ' + label; }

    let seperator = '';

    if (sub && label) { seperator = '/'; }

    const created_at = export_obj.created_at;
    const moment_date = moment(created_at);
    let formatted_date = moment_date.format('MMM Do, YYYY');

    if (date === false) { formatted_date = ''; }

    title = `${sub} ${seperator} ${label} / ${formatted_date}`;

    return title;
  }

  format_export_title(exp: any, full: boolean = true, without_name: boolean = false) {
    let title = '';
    if (exp) {

      let sub_variety = '';
      const packer = '';
      let label = '';

      if (typeof exp === 'undefined' || exp === null) {
        return title;
      }
      if (typeof exp.report === 'undefined' || exp.report === null) {
        return title;
      }
      if (typeof exp.report.fields === 'undefined') {
        return title;
      }
      let user_name = '';

      if (exp.user === null || typeof exp.user === 'undefined') {
        user_name = exp.report.user.name;
      } else {
        user_name = exp.user.name;
      }

      let formatted_time = '';
      if (exp.carbon_date !== null && typeof exp.carbon_date !== 'undefined') {
        formatted_time = exp.carbon_date;
      } else {
        formatted_time = exp.report.carbon_date;
      }
      // 2020-12-28 09:51:09
      const moment_date = moment.unix(parseInt(formatted_time, 10));
      formatted_time = moment_date.format('MMM Do, YYYY');

      // console.log(exp.report.fields);

      sub_variety = this.find_field(exp.report.fields, 'variety_multiple');
      if (sub_variety.length === 0) { sub_variety = this.find_field(exp.report.fields, 'variety'); }

      let variety = '';
      if (exp.report.type !== null || typeof exp.report.type !== 'undefined') {
        variety = exp.report.type.name;
      }

      label = this.find_field(exp.report.fields, 'label');

      if (variety && sub_variety) { sub_variety = '/ ' + sub_variety; }

      let sizing = '';

      sizing = this.find_field(exp.report.fields, 'sizing');
      if (!sizing) {
        sizing = this.find_field(exp.report.fields, 'berry_bunch_size');
      }

      if (label.length > 0 && sub_variety.length > 0) { label = '/ ' + label; }

      if (sizing.length) { sizing = '/ ' + sizing; }

      if (without_name) {
        user_name = '';
      }

      let destination = '';

      destination = this.find_field(exp.report.fields, 'destination_multiple');
      if (destination.length > 0) { destination = '/ ' + destination; }


      title = `${(user_name) ? user_name + ' - ' : ''} ${variety} ${(sub_variety)} ${label} ${sizing} ${destination} / ${formatted_time}`;

      if (full === false) {
        title = `${sub_variety} / ${label}`;
      }
    }
    return title;
  }

  private find_field(fields: Field[], string: string = '', key: string = 'name'): string {

    let val = '';
    fields.forEach((field: Field) => {
      // console.log(field.field_types, 'field_types');

      if (field.field_types[0][key] == string) {
        val = field.value;
      }
    });
    return val;
  }

  mobile_device() {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  }

  create_UUID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  copy_value_ios(text: string) {
    if (document) {
      const text_area = document.createElement('textarea');
      text_area.readOnly = true;
      (text_area.contentEditable as unknown as boolean) = true;
      text_area.value = text;
      document.body.appendChild(text_area);

      const range = document.createRange();
      range.selectNode(text_area);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      text_area.setSelectionRange(0, 99999);
      document.execCommand('copy');

      text_area.remove();
    }
  }
}
