import { Injectable } from '@angular/core';
import {PhotoComponent} from "./photo/photo.component";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PhototrackerService {

  public photos: PhotoComponent[] = [];

  public current_photo: PhotoComponent;

  public trigger: Subject<boolean> =  new Subject<boolean>();

  constructor() { }

  open(photo) {
    this.current_photo = photo;
    this.trigger.next(true);
  }

  close() {
    this.trigger.next(false);
  }
}
