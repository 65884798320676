import {Component, OnInit} from '@angular/core'
import {UserRoleService} from '../user-role.service'
import {WrapperClassService} from '../wrapper-class.service'
import {ApiService} from '../api-service.service'
import {HelperService} from '../helper.service'
import { BreadcrumbService } from '../breadcrumb.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  dash_links: any[] = []
  exports: any[] = []
  reports: any[] = []

  constructor(
    private user: UserRoleService,
    private cls: WrapperClassService,
    private api: ApiService,
    public helper: HelperService,
    private breadcrumbService: BreadcrumbService,
  ) {
    cls.add_to_class('dashboard-page')
  }

  ngOnInit() {
    // console.log(this.user);
    if (this.user.user_role === 'sales') {
      this.dash_links.push(
        {
          title: 'Recent Field Reports',
          icon: 'bar_chart',
          link: '/reports'
        }
      )
      this.dash_links.push(
        {
          title: 'Sent Sales Reports',
          icon: 'forward',
          link: '/exports'
        }
      )
    } else if (this.user.user_role === 'reporter') {
      this.dash_links.push(
        {
          title: 'My Field Reports',
          icon: 'bar_chart',
          link: '/reports'
        }
      )
    }

    if (this.user.user_role === 'admin') {
      this.dash_links.push(
        {
          title: 'My Field Reports',
          icon: 'bar_chart',
          link: '/reports'
        }
      )
      this.dash_links.push(
        {
          title: 'All Field Reports',
          icon: 'bar_chart',
          link: '/varieties'
        }
      )
      this.dash_links.push(
        {
          title: 'Sent Sales Reports',
          icon: 'send',
          link: '/exports'
        }
      )
    }
    this.dash_links.push(
      {
        title : 'Logout',
        icon : 'logout',
        link : '/logout'
      }
    )

    // Grab most recent reports....
    this.api.dashboard().subscribe((data: any) => {
      console.log(data)

      this.exports = data.exports

      this.exports.forEach(exp => {
        exp.fancy_title = this.helper.format_export_title(exp)
      })

      this.reports = data.reports
      this.reports.forEach(report => {
        report.fancy_title = this.helper.format_export_title({report})
      })
    })

    this.breadcrumbService.setSegments([])
  }

  logged_in() {
    return (localStorage.getItem('user_id'))
  }
}
