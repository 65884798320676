import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type BreadcrumbSegment = {
  name: string
  link: string
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  breadcrumbs = new BehaviorSubject<BreadcrumbSegment[]>([])

  getSegments() {
    return this.breadcrumbs.asObservable()
  }

  setSegments(segments: BreadcrumbSegment[]) {
    this.breadcrumbs.next(segments)
  }

  appendSegments(segments: BreadcrumbSegment[]) {
    this.breadcrumbs.next([
      ...this.breadcrumbs.getValue(),
      ...segments
    ])
  }
}
