import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Selection } from "../reports/reports.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "../api-service.service";
import { HelperService } from "../helper.service";

@Component({
	selector: "app-search-form",
	templateUrl: "./search-form.component.html",
	styleUrls: ["./search-form.component.scss"],
})
export class SearchFormComponent implements OnInit {
	@Input() reports: Selection[];
	@Input() api_function: (phrase: string) => void | null;
	@Input() clear_function: () => void | null;

	@Output() reportsChange = new EventEmitter<Selection[]>();

	// public form: FormBuilder;
	public search_form: FormGroup;

	private internal_selections: Selection[] = [];

	constructor(
		public form: FormBuilder,
		private api: ApiService,
		private helper: HelperService
	) {
		this.search_form = this.form.group({
			phrase: "",
			from_date: "",
			Packer: "",
		});
	}

	ngOnInit(): void {}

	async submit(event) {
		console.log(this.search_form.value);

		if (this.api_function !== null) {
			let data: any = await this.api_function(this.search_form.value.phrase);
			// console.log(data, 'done?');
			// this.internal_selections = data.data;
			// this.add();
			return;
		}

		this.api.search(this.search_form.value.phrase).subscribe((data: any) => {
			this.internal_selections = [];
			let exp: any = {};
			data.forEach((exp) => {
				let selection: Selection = {
					text: exp.title,
					editable: true,
					link: "/export/" + exp.slug,
					display: "block",
					type: "export",
					value: exp.id,
					clickable: true,
					count: null,
				};
				this.internal_selections.push(selection);
			});
			this.add();
		});

		return;
	}

	clear(ev) {
		ev.preventDefault();

		this.clear_function();
		return;
		this.api.get_exports().subscribe((exports: any) => {
			// this.sent_request = true;
			this.internal_selections = [];
			exports.forEach((exp) => {
				console.log(exp);
				let selection: Selection = {
					text: this.helper.format_export_title(exp),
					editable: true,
					link: "/export/" + exp.slug,
					display: "block",
					type: "export",
					value: exp.id,
					clickable: true,
					count: null,
				};
				this.internal_selections.push(selection);
			});
		});
	}

	add(): void {
		this.reportsChange.emit(this.internal_selections);
	}
}
