<div class="navigation" *ngIf="logged_in()">
  <div class="navigation-inner">
    <div class="left hamburger" (click)="this.hamburger.toggle()">
      <app-hamburger></app-hamburger>
      <app-breadcrumbs></app-breadcrumbs>
    </div>

    <div class="right">
      <div class="create-account">Create account</div>

      <div class="add-report" (click)="add_report($event)" *ngIf="roleService.user_role != 'sales'">
        New Report
        <div class="add-icon">
          <mat-icon>add</mat-icon>
        </div>
      </div>
    </div>
  </div>
  <app-back-button></app-back-button>
</div>

<app-mobile-navigation *ngIf="logged_in()"></app-mobile-navigation>
