<div [class]="'reports page-inner-wrapper ' + ((loading) ? 'loading' : '')">

  <div class="sort-by-field" *ngIf="selection_type !== 'none'">
    <div class="label">Sort By</div>
    <mat-form-field class="sort-by">

      <mat-select #sortby (selectionChange)="update_navigation($event)" [(value)]="selection_type">
        <mat-option *ngFor="let option of nav_options" [value]="option">
          {{option}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="empty" *ngIf="selections.length === 0 && this.sent_request">
    <mat-icon>help</mat-icon>
    <h2>No reports found...</h2>
    <button class="btn" (click)="back_button_click($event)">Go Back</button>

  </div>
  <div *ngFor="let selection of selections" class="selection" [ngStyle]="{display:selection.display}" #rep>
    <div class="background">
      <mat-icon>delete</mat-icon>
    </div>

    <div class="foreground"
      [@toggleBack]="{value : selection.animate_state, params: {state_value: selection.animate_state, panning: selection.panning}}"
      (click)="selection_click(selection);"
      (@toggleBack.start)="animation_start($event, selection);"
      (@toggleBack.done)="animation_done($event, selection);"
    >
      <div class="title">
        {{selection.text}}
        <div class="count" *ngIf="!selection.editable">{{selection.count}}</div>
        <mat-icon *ngIf="selection.editable" (click)="click_edit($event, selection.value, selection)">edit</mat-icon>
      </div>
    </div>
  </div>

  <div class="pagination">
    <!-- These values are all placeholders, just need filled in with the real deal and made functional -->
    <mat-paginator
      *ngIf="paginated"
      (page)="paginate($event)"
      [pageSizeOptions]="[20]"
      [pageIndex]="pagination.current_page - 1"
      [length]="pagination.total"
      [pageSize]="20"
    ></mat-paginator>
  </div>
</div>

<app-search-form [(reports)]="selections" [clear_function]="search_options.clearCallback" [api_function]="this.search_options.searchCallback" *ngIf="this.search_options.searchEnabled"></app-search-form>
