import {Component, OnInit} from '@angular/core'
import {ApiService} from '../api-service.service'
import {ActivatedRoute, Router} from '@angular/router'
import {UserRoleService} from '../user-role.service'
import { BreadcrumbService } from '../breadcrumb.service'

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent implements OnInit {
  public report_id = 0
  public report: any = {
    author: {name: ''},
    type: {name: ''}
  }
  public fields: any = []
  public images: any = []
  public can_edit = false

  fullscreenImage: number | null = null

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    public role: UserRoleService
  ) {

    route.paramMap.subscribe((params: any) => {
      this.report_id = params.params.id
    })
  }

  deleteImage(id: string) {
    console.log('deleting image', id)
    this.api.delete_photo(parseInt(id, 10)).subscribe(data => {
      this.images = this.images.filter(img => img.id !== id)
    })
  }

  find_field(name) {
    return this.fields.find(val => {
      return (val.field_types[0].name === name)
    })
  }

  go_back() {
    if (window) {
      window.history.back()
    }
  }

  ngOnInit() {
    this.api.get_report(this.report_id).subscribe((report: any) => {
      this.breadcrumbService.setSegments([
        { name: 'Recent Reports', link: '/reports' },
        { name: report.name, link: '/reports' + report.id }
      ])

      this.report = report
      this.fields = this.prep_fields(report.fields)
      this.images = report.images

      if (this.report.user_id === parseInt(localStorage.getItem('user_id'), 10)) {
        this.can_edit = true
      }
    })
  }

  prep_fields(fields) {
    fields.forEach(field => {
      field.prepped = field.value
      /*if (field.field_types[0].name == 'pack_date') {
        if(field.value) {
          field.prepped = new DatePipe('en-US').transform(field.value, "M-d-yyyy");
        }
      }*/

      if (field.field_types[0].type === 'select') {
        field.prepped = field.prepped.charAt(0).toUpperCase() + field.prepped.slice(1)
        field.prepped = field.prepped.replace('_', ' ')
      }
    })
    return fields
  }
}
