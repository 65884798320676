import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

type ReportImage = {
  src: string
}

@Component({
  selector: 'app-fullscreen-photoviewer',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.scss']
})
export class FullscreenComponent implements OnInit {
  @Input() images: ReportImage[] = []
  @Input() initialIndex = 0

  @Output() close = new EventEmitter()

  index = 0

  constructor(private dom_san: DomSanitizer) {}

  ngOnInit() {
    this.index = this.initialIndex
  }

  @HostListener('window:keydown', ['$event'])
  handleKeys(ev: KeyboardEvent) {
    switch(ev.key) {
      case 'ArrowRight':
        this.next()
        break
      case 'ArrowLeft':
        this.prev()
        break
      case 'Escape':
        this.close.emit()
    }
  }

  has_next(){
    return this.index < this.images.length - 1
  }

  has_prev() {
    return this.index > 0
  }

  prev() {
    this.index = Math.max(0, this.index - 1)
  }

  next() {
    this.index = Math.min(this.images.length - 1, this.index + 1)
  }

  urlToStyle(src) {
    return this.dom_san.bypassSecurityTrustStyle(`url(${src})`)
  }
}
