import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss'],
})
export class PhotoComponent {
  constructor(
    public domSanitizer: DomSanitizer,
  ) {}

  @Input() src: string
  @Input() editable = true
  @Input() progress = 0
  @Input() downloadable = false

  @Output() delete = new EventEmitter()
  @Output() fullscreen = new EventEmitter()

  download() {
    window.open(this.src, 'Download')
  }
}
