import { Injectable } from '@angular/core'
import {Subject} from 'rxjs'
import {Location} from '@angular/common'
import {NavigationEnd, Router} from '@angular/router'
import {WrapperClassService} from './wrapper-class.service'

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  public history: any[] = []
  public tracker = new Subject<any>()

  private back = false

  constructor(private location: Location, private router: Router, private cls: WrapperClassService) {

    this.router.events.subscribe(change => {
      // console.log('Router change.');
      if (change instanceof NavigationEnd) {
        // console.log('Events changed, navigation end', change);
        if (this.back) {
          this.trim();
          this.back = false
        } else {
          this.history.push(change);
          localStorage.setItem('history', JSON.stringify(this.history));
        }

        if (this.back_visible()) {
          this.cls.add_to_class('back_visible')
        } else {
          this.cls.class_list = this.cls.current_class.replace('back_visible', '');

        }
      }
    });

    console.log('first construction');
    if (localStorage.getItem('history')) {
      this.history = JSON.parse(localStorage.getItem('history'));
    }
  }

  trim() {
    this.history.splice(this.history.length - 1, 1);
  }

  is_top_level_menu() {
    return ['/varieties', '/reports', '/users', '/exports'].includes(this.router.url)
  }

  go_back() {
    if (this.is_top_level_menu()) {
      this.router.navigate(['/dashboard']);
    } else {
      if (typeof this.history[this.history.length - 2] !== 'undefined') {
        const href = this.history[this.history.length - 2].url;
        this.back = true;
        this.router.navigate([href]);
      } else {
        this.router.navigate(['dashboard']);
      }
    }
  }

  back_visible(): boolean {
    return ((this.history.length > 1) && (!this.history[this.history.length - 2].url.includes('login'))) || (this.is_top_level_menu());
  }
}
