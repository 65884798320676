import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public mode: string = 'indeterminate';
  public value : number = 0;


  loading : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.loading.next(false);
  }



  swap_modes() {
    if(this.mode == 'determinate')
      this.mode = 'indeterminate';
    else
      this.mode = 'determinate';

    return this.mode;
  }
}
