import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {BehaviorSubject, Observable, Subject, throwError} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {MessagesService, MessageType} from "./messages.service";
import {LoadingService} from "./loading.service";
import {HistoryService} from "./history.service";
import {PhototrackerService} from "./phototracker.service";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  public loading_services: LoadingService[] = [];
  // public history: any[] = [];
  // public tracker : Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private messages: MessagesService,
    private loading: LoadingService,
    private history: HistoryService,
    private pt: PhototrackerService
  ) {
    router.events.subscribe(route_change => {
      this.pt.photos = [];
    })
  }

  error_interceptor(err : any, caught){
    this.loading.loading.next(false);
    if (err.status === 401) {
      // localStorage.clear();
      let red = this.router.url;
      if(red.includes('login')){

        red = "dashboard";
      }else {
        this.messages.add_message("Your login credentials have expired.", MessageType.Error);
      }
      this.router.navigate(['/login'], { queryParams: {red : red}});
    }
    // return caught;
    return throwError(err);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loading.loading.next(true);
    // this.pt.photos = [];

    if (localStorage.getItem("api_token")) {
      let custom_request = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + localStorage.getItem("api_token"))
      });
      return next.handle(custom_request).pipe(
        catchError(this.error_interceptor.bind(this))
      ).pipe(
        map((resp) => {
          if(resp instanceof HttpResponse) {
            this.history.tracker.next(resp);
            // this.history.history.push(resp);
            this.loading.loading.next(false);
          }
          return resp;
        })
      );
    }
    return next.handle(req).pipe(catchError(this.error_interceptor.bind(this)), map(evt => {
      this.loading.loading.next(false);
      return evt;
    }));
  }
}
