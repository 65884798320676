<div class="client-bar" *ngIf="!logged_in()">
  <img [id]="'logo'" src="/assets/logo.png" alt="Logo" />
</div>

<div class="page-inner-wrapper export-view">

  <!--<div class="info" *ngIf="export">

  </div>
  <div class="info" *ngIf="export">
    <div class="i">
      <span class="title">Variety</span>
      <h5>{{ export.report.type.name | titlecase}}</h5>
    </div>
  </div>-->

  <div class="info" *ngIf="export.report">
    <div class="i">
      <h5>{{ export.title }}</h5>
    </div>
  </div>

  <app-photo-gallery
    [images]="images"
    [editable]="false"
    [sortable]="false"
    [downloadable]="true"
  ></app-photo-gallery>

  <div class="content" [innerHTML]="content"></div>
  <div class="fields" *ngIf="fields.length">

    <div *ngFor="let field of fields"
         [ngClass]="((field.field_types[0].name == 'notes' || field.value.length > 50) ? 'full': '') + ((field.field_types[0].exportable) ? ' exportable' : ' not-exportable')">
      <span class="title">{{field.field_types[0].label}}:</span>
      <h5 class="value">{{field.value}}</h5>
    </div>
  </div>

  <div class="expire-disclaimer">
    <p>The link to view this report will expire 14 days from the date it was generated.</p>
  </div>
</div>
