import { Injectable } from '@angular/core'
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http'
import { environment } from '../environments/environment'
import { PhotoUploadService} from './photo-upload.service'
import { Observable } from 'rxjs'
import { share } from 'rxjs/operators'

export type Variety = {
  id: number
  name: string
  reports_count: number
}

export type SubVariety = {
  id: number,
  name: string
  value: string
  reports_count: number
}

export type Report = {
  id: number
  name: string
  user: User
  type: Variety
  formatted_date_time: string
  fields: Field[]
}

export type User = {
  id: number
  email: string
  name: string
}

export type Field = {
  id: number
  order: number
  field_types: [FieldType]
}

export type FieldType = {
  id: number
  label: string
  name: string
  placeholder: string
  exportable: boolean
  multiple: boolean
  type: 'select' | 'text'
}

export type AppImage = {
  id: number
  src: string
  order: number
}

export type PageableResponse<T> = {
  data: T[]
  current_page: number
  from: number
  to: number
  total: number
  last_page: number
  prev_page_url: string | null
  next_page_url: string | null
  last_page_url: string
  first_page_url: string
  path: string
  per_page: number
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiLink = environment.backend + 'api/'
  headers = new HttpHeaders({ 'content-type': 'application/json', Accept: 'application/json' })

  constructor(private http: HttpClient, private photo_upload: PhotoUploadService) {}

  public search(phrase) {
    return this.http.post(this.apiLink + 'search', {phrase}, {headers: this.headers})
  }

  /*short list of recent reports for dashboard*/
  public dashboard() {
    return this.http.get(this.apiLink + 'dashboard', {headers: this.headers})
  }

  public get_export(url) {
    return this.http.get(this.apiLink + 'exports/' + url, {headers: this.headers})
  }
  public get_exports(page: number = 1) {
    return this.http.get(this.apiLink + 'exports?page=' + page, {headers: this.headers})
  }
  public get_export_by_id(id) {
    return this.http.get(this.apiLink + 'export/' + id, {headers: this.headers})
  }

  public send_report(report_id) {
    return this.http.post(this.apiLink + 'report/send', {report_id}, {headers: this.headers})
  }

  public logged_in( ): boolean {
    const api_token = localStorage.getItem('api_token')
    return (api_token !== null)
  }

  public list_users(role: string = 'reporter') {
    return this.http.get(`${this.apiLink}users/${role}`)
  }

  public list_types() {
    return this.http.get<Variety[]>(this.apiLink + 'types', {headers: this.headers})
  }

  public list_sub_varieties(type_id: number) {
    return this.http.post<SubVariety[]>(this.apiLink + 'sub_varieties', { type_id }, {headers: this.headers})
  }

  public login(username: string, password: string) {
    return this.http.post(this.apiLink + 'login', {email: username, password}, {headers: this.headers})
  }

  public list_reports() {
    return this.http.get(this.apiLink + 'reports', {headers: this.headers})
  }

  public list_varieties() {
    return this.http.get(this.apiLink + 'varieties', {headers: this.headers})
  }

  public list_reports_by_field(params: unknown, page_number: number = 1) {
    return this.http.post<PageableResponse<Report>>(`${this.apiLink}reports_by_field?page=${page_number}`, params, {headers: this.headers})
  }

  public list_reports_by_type(type_id: number = 0) {
    return this.http.post(`${this.apiLink}reports_by_type/${type_id}`, {headers: this.headers})
  }

  public all_recent_reports(page_number: number = 1, search_phrase: string = '', type_id: number = 0) {

    return this.http.get(`${this.apiLink}reports/recent?page=${page_number}&search_phrase=${search_phrase}`, {headers: this.headers})
  }

  public list_reports_by_user(user_id: number = 0, page_number: number = 1) {
    return this.http.get<PageableResponse<Report>>(`${this.apiLink}reports_by_user/${user_id}?page=${page_number}`, {headers: this.headers})
  }

  public update_field(field_id = 0, value, selected = false) {
    return this.http.post(this.apiLink + 'fields/update', {field_id, value, selected}, {headers: this.headers})
  }

  public update_images(images = []) {
    images = images.map(img => ({ id: img.id, order: img.order }))

    return this.http.post<{ message: string, image: AppImage[] }>(
      this.apiLink + 'images/update',
      { images },
      { headers: this.headers }
    )
  }

  public update_image(image_id = 0, selected = false, order: any = false) {
    return this.http.post(this.apiLink + 'image/update', {image_id, selected, order}, {headers: this.headers})
  }

  public list_fields_for_type(type_id = 0, report_id: any = 0, refresh_fields: boolean = false) {
    return this.http.post(this.apiLink + 'fields', {type_id, report_id, refresh_fields}, {headers: this.headers})
  }

  public list_fields_by_report(report_id = 0) {
    return this.http.post(this.apiLink + 'fields_by_report', {report_id}, {headers: this.headers})
  }

  uploadImage(
    image: Blob,
    report_id: number,
    order = 0,
    orientation = 1,
    last_modified = 0
  ): Observable<HttpEvent<any>> {
    // console.log(orientation);

    const form_data = new FormData()
    form_data.append('image', image, 'test.jpg')
    form_data.append('report_id', report_id.toString())
    form_data.append('order', order.toString())
    form_data.append('orientation', orientation.toString())
    form_data.append('last_modified', last_modified.toString())
    const req = new HttpRequest('POST', this.apiLink + 'report/upload_image', form_data, { reportProgress: true })

    return this.http.request(req).pipe(share())
  }

  public get_report(report_id: number = 0) {
    return this.http.get(this.apiLink + 'report/' + report_id, {headers: this.headers})
  }

  public delete_report(report_id: number = 0) {
    return this.http.post(this.apiLink + 'report/delete', { report_id }, { headers: this.headers })
  }

  public delete_photo(photo_id: number = 0) {
    return this.http.get(this.apiLink + 'report/delete_photo/' + photo_id, {headers: this.headers})
  }

  public set_report_type(report_id: number = 0, type_id: number = 0) {
    return this.http.post(this.apiLink + 'report/type/update', {report_id, type_id}, {headers: this.headers})
  }

  public logout() {
    if (localStorage.getItem('api_token') !== null) {
      localStorage.removeItem('api_token')
      localStorage.removeItem('name')
      localStorage.removeItem('user_id')
      localStorage.removeItem('user_role')
    }
  }

  public create_export_from_report(report_id: number = 0, export_id: number = 0) {
    return this.http.post(this.apiLink + 'export/create_from/' + report_id + '/' + export_id, {
      report_id,
      export_id
    }, {headers: this.headers})
  }

  public update_export_content(export_id: number = 0, content: string = '') {
    return this.http.post(this.apiLink + 'export/update_content/' + export_id, {
      export_id,
      content
    }, {headers: this.headers})
  }
}
