import {Component, OnInit} from '@angular/core';
import {ApiService} from "../api-service.service";
import {WrapperClassService} from "../wrapper-class.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {MessagesService, MessageType} from "../messages.service";
import {UserRoleService} from "../user-role.service";
import {catchError} from "rxjs/operators";

import {filter} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {HttpRequest} from "@angular/common/http";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  login_form: FormGroup;
  button_text: string = "Submit";
  error: boolean = false;

  private params: any[] = [];
  public redirect_to: string = "";

  constructor(
    private api: ApiService,
    private cls: WrapperClassService,
    private fb: FormBuilder,
    private router: Router,
    private messages: MessagesService,
    private role: UserRoleService,
    private a_route: ActivatedRoute
  ) {

    cls.class_list = "login-page";
  }

  is_key_valid() {
    return this.api.get_exports();
  }

  ngOnInit() {

    this.api.list_users().subscribe(data => {

      console.log(data);
      this.messages.add_message("You are logged in.");
      this.handle_redirection();
    }, err => {
      console.log(err, 'err');
    });

    this.login_form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    this.a_route.queryParams
      .subscribe((params: any) => {
        this.params = params;
        if (typeof params.red !== 'undefined')
          if(params.red != "" || params.red != "login")
            this.redirect_to = params.red;
      });
  }

  submit_form(ev) {
    let credentials = this.login_form.value;
    if (this.login_form.valid) {
      this.button_text = "Logging in...";
      this.api.login(credentials.email, credentials.password).subscribe(data => {
        localStorage.setItem("api_token", (<any>data).token);
        localStorage.setItem("email", credentials.email);
        localStorage.setItem("name", (<any>data).name); // add their name
        localStorage.setItem("user_id", (<any>data).id);
        localStorage.setItem("user_role", (<any>data).role);
        this.role.user_role = (<any>data).role;

        if(this.role.user_role == 'sales') this.redirect_to = '/reports/all';

        this.messages.add_message("Successfully logged in", MessageType.Normal);

        this.handle_redirection();
      }, err => {
        if (err.status == 401) {
          this.error = true;

          this.button_text = "Submit";
        }
      });
    }

  }

  handle_redirection() {
    if(!this.redirect_to) {
      this.router.navigate(['dashboard']);
    } else {
      this.router.navigate([this.redirect_to]);
    }
  }

}
