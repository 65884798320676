import { Injectable } from '@angular/core'
import {Observable, Subject} from 'rxjs'

export enum MessageType {
  Error = 'error',
  Normal = 'normal',
  Success = 'success'
}

export interface Message {
  message: string,
  type: MessageType
}

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  public messages: Subject<Message> = new Subject()

  public message_log: Message[] = []

  constructor() {
    this.messages.subscribe(message => this.message_log.push(message))
  }

  public add_message(message: string, type: MessageType = MessageType.Normal, timing: number = 5000): boolean {
    this.messages.next({ message, type })
    return true
  }
}
