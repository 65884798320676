import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { ApiService } from "../api-service.service";
import { ActivatedRoute } from "@angular/router";
import { MessagesService, MessageType } from "../messages.service";
import { HelperService } from "../helper.service";

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

  share_link_base = ''

  @ViewChild("linkInput", { static: true }) link_input: ElementRef;

  public export_id = 0;

  public report_id: number = 0;

  loading = true

  public copy_text: string = "Copy";

  public title: string = "";
  public content: string = "";

  public export_title: string = "";

  constructor(@Inject(DOCUMENT) private document: Document,
    private api: ApiService, private route: ActivatedRoute,
    private message: MessagesService,
    public helper: HelperService
  ) {
    route.params.forEach(param => {
      this.export_id = param.id;
    });
  }

  ngOnInit() {
    this.api.get_export_by_id(this.export_id).subscribe((data: any) => {
      this.share_link_base = document.location.origin + '/export/' + data.slug
      console.log(data);
      this.title = this.helper.format_export_title(data, true, true);
      this.export_title = data.export_title;
      this.content = data.content;
      this.report_id = data.report_id;

      this.loading = false
    });
  }

  has_share() {
    return (typeof (<any>navigator).share !== "undefined")
  }

  send_email() {
    window.open(`mailto:example@example.com?subject=${this.export_title}&body=${encodeURIComponent(this.share_link_base)}`, '_blank');
  }
  send_whatsapp() {
    window.open(`https://wa.me/?text=${encodeURIComponent(this.share_link_base)}`, '_blank');
  }
  share_report(ev) {
    const share_data = {
      title: this.export_title,
      text: '',
      url: this.share_link_base
    };
    (<any>navigator).share(share_data);
  }

  copy(ev) {

    this.copy_text = "Copied!";
    return this.helper.copy_value_ios(this.link_input.nativeElement.value);

    if (!this.helper.is_ios()) {
      this.link_input.nativeElement.select();
      this.document.execCommand("copy");
      this.message.add_message("Copied the link", MessageType.Success)
    } else {
      // IOS works differently.
    }
  }
}
