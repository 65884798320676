import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList
} from '@angular/core'

declare let $: any;

// Sortable photos

@Component({
  selector: 'app-sortable',
  templateUrl: './sortable.component.html',
  styleUrls: ['./sortable.component.scss']
})
export class SortableComponent<T> implements AfterViewInit {
  @Output() updated = new EventEmitter<HTMLElement[]>()

  private sortable: any
  private _disabled = false
  @Input()
  get disabled() {
    return this._disabled
  }
  set disabled(value: boolean) {
    console.log('sortable disabled', value)
    if (value !== this._disabled && this.sortable) {
      this.sortable('option', 'disabled', value)
    }
    this._disabled = value
  }

  @ContentChildren('*') contentChildren: QueryList<HTMLElement>

  constructor(private ref: ElementRef) {}

  ngAfterViewInit(): void {
    this.sortable = $(this.ref.nativeElement).sortable({
      update: this.orderUpdated.bind(this),
      placeholder: 'ui-state-highlight',
      disabled: this._disabled
      // containment: ".wrapper"
    })
  }

  orderUpdated() {
    const children = Array.from(this.ref.nativeElement.children as HTMLCollection)
    // console.log('orderUpdated', children)
    this.updated.emit(children as HTMLElement[])
  }
}
