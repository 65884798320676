import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class PhotoUploadService {

  public uploading_images: any[] = []
  public compressing = false

  constructor() { }

  remove(image: any) {
    const deletion_index = this.uploading_images.indexOf(image)
    if (deletion_index !== -1) {
      this.uploading_images.splice(deletion_index, 1)
    }
  }

  uploading() {
    return (this.uploading_images.length && !this.compressing)
  }

}
