import * as Rollbar from 'rollbar'

import { BrowserModule } from '@angular/platform-browser'
import { ErrorHandler, Inject, Injectable, InjectionToken, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
	HTTP_INTERCEPTORS,
	HttpClientModule,
} from '@angular/common/http'

import { MaterialModule } from './material-module'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { LoginComponent } from './login/login.component'
import { HttpInterceptorService } from './http-interceptor.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NavigationComponent } from './navigation/navigation.component'
import { HamburgerComponent } from './hamburger/hamburger.component'
import { ReportsComponent } from './reports/reports.component'
import { ReportComponent } from './report/report.component'
import { ReportLinkComponent } from './report-link/report-link.component'
import { MobileNavigationComponent } from './snippets/mobile-navigation/mobile-navigation.component'
import { LogoutComponent } from './logout/logout.component'
import { MessagesComponent } from './messages/messages.component'
import { PhotoComponent } from './photo/photo.component'
import { DialogComponent } from './dialog/dialog.component'
import { ReportViewComponent } from './report-view/report-view.component'
import { DateComponent } from './snippets/date/date.component'
import { BackButtonComponent } from './snippets/back-button/back-button.component'
import { ContextMenuComponent } from './context-menu/context-menu.component'
import { ExportViewComponent } from './export-view/export-view.component'
import { ShareComponent } from './share/share.component'
import { DashboardComponent } from './dashboard/dashboard.component'
import { FullscreenComponent } from './fullscreen/fullscreen.component'
import { SortableComponent } from './sortable/sortable.component'
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component'
import { ForgotUserpassComponent } from './forgot-userpass/forgot-userpass.component'
import { SearchFormComponent } from './search-form/search-form.component'
import { CommonModule } from '@angular/common'
import { PhotoGalleryComponent } from './photo-gallery/photo-gallery.component'

const rollbarConfig = {
	accessToken: 'ab5581052591447b926c9fbef63b23c7',
	captureUncaught: true,
	captureUnhandledRejections: true,
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar')

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
	constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

	handleError(err: any): void {
		this.rollbar.error(err.originalError || err)
	}
}

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		NavigationComponent,
		HamburgerComponent,
		ReportsComponent,
		ReportComponent,
		ReportLinkComponent,
		MobileNavigationComponent,
		LogoutComponent,
		MessagesComponent,
		PhotoComponent,
		DialogComponent,
		ReportViewComponent,
		DateComponent,
		BackButtonComponent,
		ContextMenuComponent,
		ExportViewComponent,
		ShareComponent,
		DashboardComponent,
		FullscreenComponent,
		SortableComponent,
		BreadcrumbsComponent,
		ForgotUserpassComponent,
		SearchFormComponent,
		PhotoGalleryComponent,
	],
	entryComponents: [DialogComponent],
	imports: [
		CommonModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpInterceptorService,
			multi: true,
		},
		{ provide: ErrorHandler, useClass: RollbarErrorHandler },
		{ provide: RollbarService, useFactory: () => new Rollbar(rollbarConfig) },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
