import {Component, OnInit} from '@angular/core';
import {Message, MessagesService, MessageType} from "../messages.service";
import {NgStyle} from "@angular/common";

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  message: string = "";
  class: string = "";
  show: string = "hidden";
  style: any = {opacity: '0'};

  hide_timer: any;

  constructor(private mes: MessagesService) {
  }

  ngOnInit() {
    setTimeout(_ => this.style.opacity = "1", 2000);

    this.mes.messages.subscribe((message: Message) => {
      this.set_message(message);
    });
  }

  hide_message() {
    this.show = "hidden";
    clearInterval(this.hide_timer);
  }

  show_message() {
    this.show = "show";
  }

  set_message(message: Message, timing: number = 5000) {
    this.show_message();
    this.message = message.message;
    this.class = <string>message.type;

    clearInterval(this.hide_timer);

    this.hide_timer = setInterval(_ => this.hide_message(), timing);
  }

}
