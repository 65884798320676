import { Component, OnDestroy, OnInit } from '@angular/core'
import { HamburgerService } from '../hamburger.service'
import { Router } from '@angular/router'
import { TitleService } from '../title.service'
import { UserRoleService } from '../user-role.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  constructor(
    public hamburger: HamburgerService,
    private router: Router,
    public roleService: UserRoleService
  ) {}

  logged_in() {
    return (
      localStorage.getItem('user_id')
      && this.router.url !== '/'
      && this.router.url !== '/login'
    )
  }

  add_report(ev) {
    this.router.navigate(['reports', 'create'])
  }
}
