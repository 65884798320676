import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class WrapperClassService {

  private _class_list: BehaviorSubject<string>
  public current_class = ''

  constructor() {
    this._class_list = new BehaviorSubject<string>('')
  }

  public get class_list(): string | BehaviorSubject<string> {
    return this._class_list
  }

  public set class_list(value: string | BehaviorSubject<string>) {
    this.current_class = value as string
    this._class_list.next(value as string)
  }

  public add_to_class(value) {
    const new_class = this.current_class + ' ' + value as string
    this._class_list.next(new_class)
    this.current_class = new_class
  }
}
