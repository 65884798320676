import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { CdkDragDrop, CdkDragMove, CdkDragSortEvent, moveItemInArray } from '@angular/cdk/drag-drop'
import { AppImage, ApiService } from '../api-service.service'

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss']
})
export class PhotoGalleryComponent implements AfterViewInit {
  @Input() images: AppImage[] = []
  @Input() editable = true
  @Input() downloadable = false
  @Input() sortable = false
  @Input() progress = 0 // TODO: What about this? Include in image AppImage?

  @Output() delete = new EventEmitter()
  @Output() updated = new EventEmitter<AppImage[]>()

  fullscreenIndex: number | null = null

  constructor(
    private api: ApiService,
    private domSanitizer: DomSanitizer,
  ) { }


  ngAfterViewInit() {
    // console.log(this.images, this.fullscreenIndex)
  }

  deleteImage(id: number) {
    // console.log('deleting image', id)
    this.api.delete_photo(id).subscribe(() => {
       const newImages = this.images
        .filter(img => img.id !== id)
        .map((img, i) => ({ ...img, order: i }))

       // After an image has been deleted, set the new image order
       this.api.update_images(newImages).subscribe(() => {
        this.updated.emit(newImages)
      })
    })
  }

  openFullscreen(imageId: number) {
    console.log('openFullscreen', typeof imageId, imageId)
    this.fullscreenIndex = this.images.findIndex(img => img.id === imageId)
  }

  drop(elements: HTMLElement[]) {
    const newImages = elements
      .map(el => parseInt(el.dataset.id, 10))
      .map((id, i) => {
        const image = this.images.find(img => img.id === id)
        return {
          ...image,
          order: i
        }
      })
      .sort((a, b) => {
        return a.order - b.order
      })

    this.api.update_images(newImages).subscribe(() => {
      this.updated.emit(newImages)
    })
  }
}
