<div [class]="'page-inner-wrapper ' + class">
  <div class="form-wrapper">
    <form action="">

      <div class="info-fields">
        <div class="info-field">
          <div class="header">
            Inspector Name:
          </div>
          <div class="value">
            {{ inspector_name }}
          </div>
        </div>
        <div class="info-field">
          <div class="header">
            Report Date:
          </div>
          <app-date [date]="report_date" [time]="true"></app-date>
        </div>
      </div>

      <mat-form-field>
        <mat-label>Variety</mat-label>
        <mat-select (selectionChange)="change($event)" [value]="type">

          <mat-option *ngFor="let type of types" [value]="type.id">{{type.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div
        *ngIf="type_name != 'Lemons' && (help.object.keys(sub_groups).length === 0) && subs.length > 0"
        [ngClass]="[(variety_field.other) ? 'other' : '', 'select_wrapper'] ">
        <mat-form-field>
          <mat-label>Sub-variety</mat-label>
          <mat-select (selectionChange)="select_change(sub_field, $event)"
                      [value]="(sub_field.other) ? 'other' : sub_field.value">
            <mat-option *ngFor="let t of subs" [value]="t.value">{{t.label}}</mat-option>
            <mat-option [value]="'other'">Other</mat-option>
          </mat-select>

        </mat-form-field>
        <ng-container *ngIf="variety_field.other">
          <input matInput placeholder="Other" [value]="variety_field.value"
                 (change)="input_change(variety_field, $event)">
        </ng-container>
      </div>


      <div *ngIf="(help.object.keys(sub_groups).length > 0)"
           [ngClass]="[(variety_field.other) ? 'other' : '', 'select_wrapper']">
        <mat-form-field>
          <mat-label>
            Sub-variety
          </mat-label>
          <mat-select (selectionChange)="select_change(sub_field, $event)"
                      [value]="(sub_field.other) ? 'other' : sub_field.value">
            <mat-optgroup *ngFor="let group of help.object.keys(sub_groups)" [label]="group">
              <mat-option *ngFor="let t of sub_groups[group]" [value]="t.value">{{t.label}}</mat-option>
            </mat-optgroup>
            <mat-option value="other">Other</mat-option>

          </mat-select>
        </mat-form-field>
        <ng-container *ngIf="variety_field.other">
          <input matInput placeholder="Other" [value]="variety_field.value" (change)="input_change(variety_field, $event)">
        </ng-container>
      </div>


      <!--<mat-form-field *ngIf="subs.length === 0">

        <mat-label>Sub-variety</mat-label>
        <input matInput placeholder="Sub-variety" (change)="input_change(variety_field, $event)"
               [value]="variety_field.value">
      </mat-form-field>-->

      <div [ngClass]="'images container-fluid ' + ((report_id === -1) ? ' hidden ' : '')">

        <div class="images-sortable">
          <div class="add_image">
            <label for="add_image_input">
              <input id="add_image_input" type="file" accept="image/*;capture=camera" multiple
                     (change)="add_image($event)" (error)="err($event)">
            </label>
            <mat-icon>add</mat-icon>
          </div>

          <app-photo-gallery
            [images]="images"
            [editable]="editingEnabled"
            [sortable]="editingEnabled"
            (updated)="imagesUpdated($event)"
          ></app-photo-gallery>
        </div>
      </div>

      <div *ngFor="let field of fields" [class]="(field.field_types[0].exportable) ? 'exportable' : 'not-exportable'">
        <div *ngIf="field.field_types[0].type == 'text' || field.field_types[0].type == 'date'">
          <mat-form-field class="example-full-width">
            <mat-label>{{field.field_types[0].label}}</mat-label>
            <textarea matInput
                      cdkAutosizeMinRows="2"
                      cdkAutosizeMaxRows="4"
                      placeholder="{{field.field_types[0].placeholder}}" (change)="input_change(field, $event)"
                      [value]="field.value"></textarea>
          </mat-form-field>
        </div>
        <div [class]="'select_wrapper '+ ((field.other) ? 'other' : '')" *ngIf="field.field_types[0].type == 'select'">
          <mat-form-field [class]="'example-full-width '" *ngIf="field.field_types[0].options.length">
            <mat-label>{{field.field_types[0].label}}</mat-label>
            <mat-select (selectionChange)="select_change(field, $event); field.field_value = $event.value;"
                        [value]="(field.other) ? 'other' : field.value" *ngIf="!field.field_types[0].multiple">
              <mat-option *ngFor="let option of field.field_types[0].options" [value]="option.value" [hidden]="">
                {{option.label}}
              </mat-option>
              <mat-option value="other">Other</mat-option>
            </mat-select>

            <!--Multiple-->

            <mat-select (selectionChange)="select_change(field, $event); field.field_value = $event.value;"
                        [value]="field.value" *ngIf="field.field_types[0].multiple" [multiple]="true">
              <mat-option *ngFor="let option of field.field_types[0].options" [value]="option.value" [hidden]="">
                {{option.label}}
              </mat-option>
            </mat-select>

          </mat-form-field>
          <ng-container
            *ngIf="(field.other || field.field_types[0].options.length === 0) && !field.field_types[0].multiple">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="{{field.field_types[0].placeholder}}" (change)="input_change(field, $event)"
                     [value]="(field.other) ? field.value : ''">
            </mat-form-field>
          </ng-container>
        </div>

      </div>
    </form>
  </div>
</div>
<a [routerLink]="'/reports/'+ report_id">
  <div [class]="((loading.loading.getValue() || photo_upload.uploading()) ? 'disabled' : '') + ' view_report'">
    View Report
  </div>
</a>
