import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core'
import {WrapperClassService} from '../wrapper-class.service'
import { AppImage, ApiService } from '../api-service.service'
import {ActivatedRoute} from '@angular/router'
import {MessagesService, MessageType} from '../messages.service'

import {Location} from '@angular/common'
import {LoadingService} from '../loading.service'

import {Subject, zip} from 'rxjs'
import {debounceTime, filter} from 'rxjs/operators'
import Compressor from 'compressorjs'
import {HttpEventType, HttpProgressEvent, HttpResponse} from '@angular/common/http'
import {PhotoUploadService} from '../photo-upload.service'
import {HelperService} from '../helper.service'
import { BreadcrumbService } from '../breadcrumb.service'

declare let $: any

// console.log(CanvasCompress.getTransform, 'get Transform');

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  types: any = []
  type: any = ''
  type_name = ''
  field: any = []
  fields: any = []
  images: any = []
  varieties: any = []
  sub_groups: any = []
  sub_varieties: any = []
  inspector_name = ''
  report: any = []
  help: any = {}
  variety_field: any = []

  subs: any = {}
  sub_field: any = {}

  class = 'new'

  report_date: number | Date = Date.now()

  report_id = -1 // doubles as a check

  max_date: Date = new Date()

  @ViewChild('sortable', {static: false}) sortable: ElementRef

  fullscreenImage: number | null = null
  editingEnabled = true

  constructor(
    private api: ApiService,
    private cls: WrapperClassService,
    private route: ActivatedRoute,
    private messages: MessagesService,
    private location: Location,
    public loading: LoadingService,
    public photo_upload: PhotoUploadService,
    private breadcrumbService: BreadcrumbService,
    private changeDetector: ChangeDetectorRef,
    public helper: HelperService,
  ) {
    this.variety_field = {value: ''}
    this.help.object = Object

    cls.class_list = 'edit-page'
    // console.log(route.routeConfig.path, route);
    if (route.routeConfig.path.includes('edit')) {
      route.params.forEach(val => this.report_id = val.id)
    }
  }

  imagesUpdated(images: AppImage[]) {
    console.log('report images updated')
    this.images = images
  }

  ngOnInit() {
    this.inspector_name = localStorage.getItem('name')
    this.api.list_types().subscribe(types => {
      this.types = types
    })

    if (this.is_new()) {
      this.class = 'new'

      this.breadcrumbService.setSegments([
        { name: 'Recent Reports', link: '/reports' },
        { name: 'New Report', link: '/reports/create' }
      ])
    } else {
      this.class = 'edit'
      this.api.get_report(this.report_id).subscribe((report: any) => {
        this.breadcrumbService.setSegments([
          { name: 'Recent Reports', link: '/reports' },
          { name: report.name, link: '/reports/edit/' + report.id }
        ])

        this.type = report.type.id
        this.type_name = report.type.name
        this.report = report
        this.images = report.images
        this.variety_changed(report.type)

        this.api.list_fields_for_type(this.type, this.report_id).subscribe((data: any) => {
          // this.fields = data.fields;
          let fields = data.fields
          // console.log(this.fields);
          fields = this.filter_options(fields, this.type)
          fields.forEach(field => {
            if (field.group) {
              field[field.group].push(field)
            }
            if (!this.has_option(field.value, field)) {
              field.other = true
            } else {
              field.other = false
            }


            if (field.field_types[0].name === 'variety') {
              this.variety_field = field
              this.subs = field.field_types[0].options
              this.manage_sub_groups(this.subs)


              this.sub_field = field
            }

            if (field.field_types[0].multiple) {
              field.value = field.value.split(', ')
              console.log(field.value)
            }

          })

          fields = fields.filter((field) => {
            if (field.field_types[0].name === 'variety') {

              return false
            }
            return true
          })
          this.filter_options(fields, this.type)
          this.fields = fields


          // console.log(this.fields);
          // console.log(this.types);
        })
      })
    }
  }

  manage_sub_groups(subs) {
    this.sub_groups = []
    subs.forEach(sub => {
      if (sub.group.length > 0) {
        if (typeof this.sub_groups[sub.group] === 'undefined') {

          this.sub_groups[sub.group] = []

          // console.log('Making array');
        }
        // console.log(sub.group);
        this.sub_groups[sub.group].push(sub)
      }
    })
    // console.log(this.sub_groups, 'sub groups', this.help.object.keys(this.sub_groups).length);
  }

  err(ev) {
    console.error(ev)

  }

  async add_image(event: Event) {
    const files = Array.from((event.target as HTMLInputElement).files)

    if (files.some(file => file.type.includes('video'))) {
      this.messages.add_message('Videos are not allowed.', MessageType.Error)
      return
    }

    this.editingEnabled = true

    this.loading.loading.next(true)

    this.messages.add_message('Photo upload in progress, please wait...', MessageType.Normal, )

    // about to compress, stop page refreshes.

    this.photo_upload.compressing = true

    const compressedImages = await Promise.all(files.map(compressImage))

    console.log('images', this.images)

    const uploadingImages = compressedImages.map((blob, i) => {
      const url = URL.createObjectURL(blob)

      return {
        src: url,
        id: (Math.round(Math.random() * 1000)),
        order: this.images.length + i,
        report_id: this.report_id,
        mime_type: '',
        export_id: null,
        created_at: '',
        description: null,
        alt: null,
        selected: false,
        title: 'temp.jpeg',
        updated_at: '',
        compressed: blob,
        last_modified: 0,
        progress: 0
      }
    })

    this.images = this.images.concat(uploadingImages)

    this.photo_upload.compressing = false

    this.loading.swap_modes()

    const uploads = compressedImages.map((blob, i) => {
      const imageIndex = this.images.findIndex(img => img.compressed === blob)
      const image = this.images[imageIndex]

      this.photo_upload.uploading_images.push(image)

      const request = this.api.uploadImage(blob, this.report_id, image.order, 0)

      request
        .pipe(filter(isHttpProgressEvent))
        .subscribe(progressEvent => {
          let prog = (progressEvent.loaded / progressEvent.total) * 100
          if (prog >= 100) {
            prog = 99.9 // stop it from showing completed until it actually loads back the server's 100
          }
          console.log('progress', image, prog)
          image.progress = prog

          // this.images[imageIndex] = { ...image, progress: prog }

          this.changeDetector.detectChanges()
        })

      request
        .pipe(filter(e => e instanceof HttpResponse))
        .subscribe(() => {
          image.progress = 100
          // this.images[imageIndex] = { ...image, progress: 100 }
          this.photo_upload.remove(image)

          this.changeDetector.detectChanges()
        })

      return request.toPromise()
    })

    const uploadResults = await Promise.allSettled(uploads)

    console.log(uploadResults)

    // const result = await this.api.update_images(this.images).toPromise()
    // console.log('order result', result)

    this.messages.add_message('Images successfully uploaded.')

    this.editingEnabled = true

    return true
  }

  change(ev) {
    console.log('change event')
    const chosen_type_id = ev.value
    const was_new = this.is_new() // preflight check

    this.api.list_fields_for_type(ev.value, ((this.report_id === -1) ? false : this.report_id), true).subscribe((data: any) => {
      const fields = data.fields
      this.report_id = data.report_id

      this.fields = this.filter_options(fields, chosen_type_id)
      // this.fields = fields;
      this.fields.forEach(field => {
        if (field.field_types[0].name === 'variety') {
          this.subs = field.field_types[0].options
          this.manage_sub_groups(this.subs)
          this.sub_field = field

        }
      })
      this.fields = this.fields.filter((field) => {
        if (field.field_types[0].name === 'variety') {
          return false
        }
        return true
      })

      this.api.set_report_type(this.report_id, chosen_type_id).subscribe(result => {
      })

      if (was_new) {
        this.goto_edit()
      }
    })

    this.types.forEach(tp => {
      if (tp.id === chosen_type_id) {
        this.variety_changed(tp)

      }
    })
  }

  variety_changed(type) {
    this.type_name = type.name


    const variaties_with_no_sub_variety = [
      'Lemons'
    ]
    if (variaties_with_no_sub_variety.includes(this.type_name)) {

      let sub_variety_field
      console.log(this.report.fields)
      this.report.fields.forEach(f => {
        if (f.field_types[0].name == 'variety') {
          sub_variety_field = f
        }
      })
      console.log(sub_variety_field)
      // if lemons...
      this.field_updated(sub_variety_field, '')
    }
  }

  filter_options(fields, type_id) {
    // filter out our invalid options
    for (const field of fields) {
      for (const field_type of field.field_types) {
        field_type.options = field_type.options.filter(option => {
          return (option.type_id == type_id || !option.type_id)
        })
        field_type.options = field_type.options.sort(function(a, b) {
          return (a.value < b.value) ? -1 : 1
        })
      }
    }


    return fields
  }


  /**
   * Goes to the edit URL after a report is generated in the database
   */
  goto_edit() {
    this.location.replaceState('/reports/edit/' + this.report_id)
  }

  input_change(field, ev) {
    this.field_updated(field, ev.target.value)
  }

  has_option(option, field) {
    return field.field_types[0].options.find((type: any) => {
      return type.value.includes(option)
    }) != undefined
  }

  select_change(field, ev) {

    console.log(field, ev)

    let value = ev.value
    if (value instanceof Array) {
      value = value.join(', ')
    }

    /*
    * If there's no option for this in the field type, open "Other". Also can't be multiple choice.
    * */
    if (!this.has_option(value, field) && !(ev.value instanceof Array)) {
      field.other = true
    } else {
      field.other = false
    }

    if (field.field_types[0].name == 'pack_date') {
      // console.log(ev.value, ev.value.getTime(),new Date().getTime());
      if (ev.value.getTime() > new Date().getTime()) {
        ev.target.value = new Date()
      }
    }
    // console.log(field, ev);

    console.log(value, 'Field Value', this.variety_field)

    if (field.field_types[0].name == 'variety') {
      this.variety_field = field
    }

    if (value !== 'other') {
      this.field_updated(field, value)
    }
  }


  field_updated(field, value) {
    this.api.update_field(field.id, value).subscribe(response => {
    })
  }

  is_new() {
    return (this.report_id == -1)
  }
}

function compressImage(file: File): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const c = new Compressor(file, {
      quality: 0.6,
      maxHeight: 1000,
      maxWidth: 1000,
      success(f) {
        resolve(f)
      },
      error(err) {
        reject(err)
      }
    })
  })
}

function isHttpProgressEvent(e): e is HttpProgressEvent {
  return e.type === HttpEventType.UploadProgress
}
