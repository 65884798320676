

<div class="inner-dash page-inner-wrapper">
  <div class="dash-link" *ngFor="let dash_link of dash_links" [routerLink]="[dash_link.link]">
    <mat-icon>{{dash_link.icon}}</mat-icon>
    <div class="title">{{dash_link.title}}</div>

  </div>
</div>

<div class="desktop-dash">
  <div class="sent-reports">
    <div class="top">
      <h1>Sent Sales Reports</h1>
      <a routerLink="/exports">View All</a>
    </div>
    <div class="reports" *ngIf="exports.length">

      <a *ngFor="let exp of exports" [routerLink]="'/export/' + exp.url">
        <div class="report">
          <p class="name">{{ exp.fancy_title }}</p>
        </div>
      </a>

    </div>
  </div>
  <div class="recent-reports">
    <div class="top">
      <h1>Recent Sales Reports</h1>
      <a routerLink="/reports">View All</a>
    </div>
    <div class="reports" *ngIf="reports.length">
      <a *ngFor="let report of reports" [routerLink]="'/reports/' + report.id">
        <div class="report">
          <p class="name">{{report.fancy_title}}</p>
        </div>
      </a>

    </div>
  </div>
</div>
