<div id="password-reset-page">
    <div [class]="'password-reset-inner'">
      <img [id]="'logo'" src="/assets/logo.png" alt="Logo" />
      <div class="card">
        <form [formGroup]="password_reset_form" (submit)="submit_form($event)">
          <mat-card>
  
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Email" value="" formControlName="email" type="email">
            </mat-form-field>
            <mat-error *ngIf="error">
              No account associated with that email address.
            </mat-error>
  
          </mat-card>
          <button mat-stroked-button	[color]="'primary'">{{button_text}}</button>
        </form>
      </div>
    </div>
  
  
  </div>
  