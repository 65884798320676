import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MessageType, MessagesService } from "../messages.service";
import { ApiService } from "../api-service.service";
import { WrapperClassService } from "../wrapper-class.service";
import { Router, ActivatedRoute } from "@angular/router";
import { UserRoleService } from "../user-role.service";

@Component({
	selector: "app-forgot-userpass",
	templateUrl: "./forgot-userpass.component.html",
	styleUrls: ["./forgot-userpass.component.scss"],
})
export class ForgotUserpassComponent implements OnInit {
	password_reset_form: FormGroup;
	button_text = "Reset Password";
	error = false;

	constructor(
		private api: ApiService,
		private cls: WrapperClassService,
		private fb: FormBuilder,
		private router: Router,
		private messages: MessagesService,
		private role: UserRoleService,
		private a_route: ActivatedRoute
	) {
		cls.class_list = "password-reset-page";
	}

	ngOnInit(): void {
		this.password_reset_form = this.fb.group({
			email: ["", [Validators.required, Validators.email]],
		});
	}

	submit_form(ev) {
		let credentials = this.password_reset_form.value;
		if (this.password_reset_form.valid) {
			this.button_text = "Sending...";
		}
	}
}
