import { Component, OnInit } from '@angular/core';
import {ApiService} from "../api-service.service";
import {Router} from "@angular/router";
import {MessagesService} from "../messages.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private api : ApiService, private router : Router, private messages : MessagesService) { }

  ngOnInit() {
    this.api.logout();

    localStorage.clear();

    this.messages.add_message("Successfully logged out");

    this.router.navigate(['/login']);
  }

}
