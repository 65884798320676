<div class="share page-inner-wrapper">
  <div class="inner">
    <div class="copy">
      <h6>Copy Link</h6>
      <p>Copy your link and send it, or if on mobile use the "Send Report" button to share.</p>
    </div>

    <div class="group">
      <input type="text" class="link" [value]="share_link_base" [attr.aria-disabled]="loading" #linkInput>
      <div class="copy-button" (click)="copy($event)" [attr.aria-disabled]="loading">
        {{copy_text}}
      </div>
    </div>

    <div class="button-group" *ngIf="!loading">
      <button class="btn btn-green" (click)="send_email()">
        <mat-icon>alternate_email</mat-icon>
        <p>Send Email</p>
      </button>
      <button *ngIf="!helper.mobile_device()" class="btn btn-green" (click)="send_whatsapp()">
        <mat-icon>message</mat-icon>
        <p>WhatsApp</p>
      </button>
      <button (click)="share_report($event)" class="btn btn-green" *ngIf="has_share()">
        <mat-icon>share</mat-icon>
        <p>Share Report</p>
      </button>
      <button class="btn btn-gray" [routerLink]="['/reports/export/' + report_id]">
        <p>Back to Edit</p>
      </button>
      <button class="btn btn-gray" [routerLink]="['/exports']">
        <p>View Reports</p>
      </button>
    </div>
  </div>
</div>
