import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {ReportsComponent} from './reports/reports.component';
import {ReportComponent} from './report/report.component';
import {LogoutComponent} from './logout/logout.component';
import {ReportViewComponent} from './report-view/report-view.component';
// import {ExportComponent} from "./export/export.component";
import {ExportViewComponent} from './export-view/export-view.component';
import {ShareComponent} from './share/share.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {SortableComponent} from './sortable/sortable.component';
import {ForgotUserpassComponent} from './forgot-userpass/forgot-userpass.component';


const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    data : { title: 'Home' }
  },
  {
    path: 'sortable',
    component: SortableComponent,
    data: {title: 'Sortable'}
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {title: 'Dashboard'}
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {title: 'Login'}
  },
  {
    path: 'varieties',
    component: ReportsComponent,
    data: {title: 'Field Reports'}
  },
  {
    path: 'variety/:id',
    component: ReportsComponent,
    data: {title: 'Field Reports'}
  },
  {
    path: 'reports',
    component: ReportsComponent,
    data : {title: 'My Field Reports'}
  },
  {
    path: 'reports/recent',
    component: ReportsComponent,
    data: {title: 'Recent Field Reports'}
  },
  {
    path: 'reports/all',
    component: ReportsComponent,
    data: {title: 'All Field Reports'}
  },
  {
    path: 'reports/all/:page',
    component: ReportsComponent,
    data: {title: 'All Field Reports'}
  },
  {
    path: 'reports_by_fields/:base64',
    component: ReportsComponent,
    data : {title: 'Reports by Field'}
  },
  {
    path: 'reports_by_fields/:base64/:page',
    component: ReportsComponent,
    data : {title: 'Reports by Field'}
  },
  {
    path: 'reports_by_type/:id',
    component: ReportsComponent,
    data : {title: 'Reports by Type'}
  },
  {
    path: 'users',
    component: ReportsComponent,
    data : {title: 'Users'}
  },
  {
    path: 'reports_by_user/:id',
    component: ReportsComponent,
    data : {title: 'Reports by User'}
  },
  {
    path: 'reports_by_user/:id/:page',
    component: ReportsComponent,
    data : {title: 'Reports by User'}
  },
  {
    path: 'reports/edit/:id',
    component: ReportComponent,
    data : {title: 'Edit Report'}
  },
  {
    path: 'reports/create',
    component: ReportComponent,
    data : {title: 'Create Report'}
  },
  {
    path: 'reports/:id',
    component: ReportViewComponent,
    data : {title: 'View Report'}
  },
  {
    path: 'reports/export',
    loadChildren: () => import('./export/export.module').then(m => m.ExportModule),
    // component: ExportComponent, data :{title: "Send Report"}
  }, /*
  {
    path: 'reports/export/:id/:export_id',
    loadChildren: () => import('./export/export.module').then(m => m.ExportModule),
    // component: ExportComponent, data :{title: "Edit Report"}
  },*/
  {
    path: 'exports',
    component: ReportsComponent,
    data: {title: 'Sent Reports'}
  },
  {
    path: 'exports/:page',
    component: ReportsComponent,
    data: {title: 'Sent Reports'}
  },
  {
    path: 'exports/:base64',
    component: ReportsComponent,
    data: {title: 'Sent Reports'}
  },
  {
    path: 'export/:id',
    component: ExportViewComponent,
    data: {title: 'View Report'}
  },
  {
    path: 'share/:id',
    component: ShareComponent,
    data: {title: 'View Report'}
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {title: 'Logout'}
  },
  {
    path: 'password-reset',
    component: ForgotUserpassComponent,
    data: {title: 'Reset Password'}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
