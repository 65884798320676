import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ContextOptionsService, Option} from '../context-options.service';


@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {

  @ViewChild("matCard", {static: true, read: ElementRef}) public matCard: ElementRef;
  public options: Option[] = [];
  public x: number = 0;
  public y: number = 0;
  public active: boolean = false;

  constructor(private cont: ContextOptionsService) {
    cont.options.subscribe((val: Option[]) => {
      console.log("options updated");
      this.options = val;
    });
    cont.add_option({
      name: "Test", callback: () => {
        console.log("test clicked");
      }
    });
  }

  open(pos: number[] = [0, 0]) {
    this.x = pos[0];
    this.y = pos[1];
    this.active = true;
  }

  close() {
    this.active = false;
  }

  ngOnInit() {

    setTimeout(() => {
      console.log(this.cont.context_elements)
    }, 2000);
  }

  option_clicked(event){
    console.log("Option clicked");
    this.close();

  }


}
