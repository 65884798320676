import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { WrapperClassService } from './wrapper-class.service'
import { HamburgerService } from './hamburger.service'
import { LoadingService } from './loading.service'
import { ContextMenuComponent } from './context-menu/context-menu.component'
import { ContextElement, ContextOptionsService } from './context-options.service'
import { version } from '../../package.json'

import * as Hammer from 'hammerjs'
import { environment } from 'src/environments/environment'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild('contextMenu') context_menu: ContextMenuComponent
  public title = 'paramount'
  public class_list = 'loading'
  public show_loading = false
  public progress_bar_class = 'loading'
  private click_disabled = false

  versionType = (environment as any).versionType
  public version_number = '0.0'

  constructor(
    private cls: WrapperClassService,
    private hamburger: HamburgerService,
    public loading: LoadingService,
    private context_service: ContextOptionsService,
    private self_element: ElementRef,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.version_number = version
  }

  ngOnInit() {
    (this.cls.class_list as BehaviorSubject<string>).subscribe(val => {
      if (window.location.href.includes('exports') || window.location.href.includes('reports/export/')) {
        this.class_list = 'exports'
      } else if (window.location.href.includes('export/')) {
        this.class_list = 'exported-report'
      } else {
        this.class_list = window.location.href.split('/').splice(3).join('-')
      }
    })

    this.loading.loading.subscribe(ev => {
      this.progress_bar_class = ev ? 'loading' : ''
      this.show_loading = ev

      this.changeDetector.detectChanges()
    })

    const hammer = new Hammer(this.self_element.nativeElement)
    hammer.add(new Hammer.Press({
      time: 1000
    }))
    hammer.on('press', (ev) => {
      this.context_service.context_elements.forEach((context_element: ContextElement) => {
        const path = []
        let node = ev.target
        while (node) {
          path.push(node)
          node = node.parentNode
        }

        if (path.includes(context_element.element.nativeElement)) {
          this.context_service.set_options(context_element.options)
          this.context_menu.open([ev.center.x, ev.center.y])
          this.click_disabled = true
          setTimeout(() => {
            this.click_disabled = false
          }, 1000)
        }
      })
    })
  }

  click_wrapper(event) {
    if (this.click_disabled) {
      return false
    }
    if (typeof event.path !== 'undefined') {
      if (!event.path.includes(this.context_menu.matCard.nativeElement)) {
        this.context_menu.close()
      }

      this.close_hamburger(event)
    }
  }

  context_menu_click(event) {
    if (typeof event.path !== 'undefined') {
      let context_found = false
      this.context_service.context_elements.forEach((context_element: ContextElement) => {
        if (event.path.includes(context_element.element.nativeElement)) {
          context_found = true
          this.context_service.set_options(context_element.options)
        }
      })

      if (context_found) {
        event.preventDefault()
        this.context_menu.open([event.x, event.y])
      }
    }
  }

  close_hamburger(event) {
    if (typeof event.path !== 'undefined') {
      if (!event.path.includes(document.querySelector('.hamburger-outer'))
        && !event.path.includes(document.querySelector('.mobile-navigation'))) {
        this.hamburger.hamburger_open = false
      }
    }
  }
}
