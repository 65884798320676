<div id="login-page">
  <div [class]="'login-inner'">
    <img [id]="'logo'" src="/assets/logo.png" alt="Logo" />
    <div class="card">
      <form [formGroup]="login_form" (submit)="submit_form($event)">
        <mat-card>

          <mat-form-field class="example-full-width">
            <input matInput placeholder="Email" value="" formControlName="email" type="email">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Password" value="" type="password" formControlName="password">
          </mat-form-field>
          <mat-error *ngIf="error">
            Email or password incorrect.
          </mat-error>

        </mat-card>
        <mat-radio-button value="1" labelPosition="before">Remember Me</mat-radio-button>
        <button mat-stroked-button	[color]="'primary'">{{button_text}}</button>
        <!--<div class="forgot-password">
          <a href="/password-reset">Forgot Password / Username</a>
        </div>-->
      </form>
    </div>
  </div>


</div>
