<div class="form-wrapper">
  <form [formGroup]="search_form" (ngSubmit)="submit($event)">
    <div class="title">Filters</div>
    <mat-form-field>
      <mat-label>Search</mat-label>
      <input name="phrase" formControlName="phrase" matInput>
    </mat-form-field>
    <!--<mat-form-field>
      <mat-label>From Date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="start_date">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Packer</mat-label>
      <mat-select formControlName="Packer">
        <mat-option>
          Sample Option 1
        </mat-option>
        <mat-option>
          Sample Option 2
        </mat-option>
        <mat-option>
          Sample Option 3
        </mat-option>
        <mat-option>
          Sample Option 4
        </mat-option>
      </mat-select>
    </mat-form-field>-->
    <a href="#" (click)="clear($event)">Clear Filters</a>
    <button mat-flat-button [color]="'primary'" type="submit">Search</button>
  </form>
</div>
