import {ElementRef, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from "rxjs";

export interface Option {
  name: string
  callback: (any) => void
}

export interface ContextElement{
  options: Option[],
  element: ElementRef
}

@Injectable({
  providedIn: 'root'
})
export class ContextOptionsService {

  public options: Subject<any[]> = new Subject<any[]>();
  public _options: any[] = [];
  private _open: boolean = false;
  private _pos: number[] = [0, 0];

  public context_elements: ContextElement[] = [];

  constructor() {
  }

  open(pos: number[] = [0, 0]) {
    this._open = true;
    this._pos = pos;
  }

  close() {
    this._open = false;
  }

  set_options(options: Option[]){
    this._options = options;
    this.options.next(this._options);
    return this.get_options();
  }

  add_option(opt: Option, pos: number | boolean = 0) {
    this._options.push(opt);
    this.options.next(this._options);

    return this.get_options();
  }

  remove_option(name: string) {
    let indexes_to_remove: number[] = [];
    for (let i = 0; i < this._options.length; i++) {
      let opt: Option = this._options[i];
      if (opt.name == name) {
        indexes_to_remove.push(i);
      }
    }

    for (let index of indexes_to_remove) {
      this._options[index] = null;
    }

    this._options = this._options.filter((option) => {
      return option != null;
    });

    this.options.next(this._options);
  }

  get_options() {
    return this.options;
  }
}
