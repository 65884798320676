import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { BreadcrumbSegment, BreadcrumbService } from '../breadcrumb.service'

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  private breadcrumbSubscription?: Subscription

  segments: BreadcrumbSegment[]

  constructor(
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.breadcrumbSubscription = this.breadcrumbService.getSegments().subscribe(segments => {
      this.segments = segments
    })
  }

  ngOnDestroy() {
    this.breadcrumbSubscription?.unsubscribe()
  }
}
