<div class="page-inner-wrapper report-view">

  <div class="info" *ngIf="report">
    <div class="i">
      <span class="title">Inspector Name:</span>
      <h5>{{report.author.name}}</h5>
    </div>
    <div class="i">
      <span class="title">Report Date:</span>
      <!--<h5>{{report.created_at | date: 'shortDate'}}
      <div>{{report.created_at | date: 'h:mma'}}</div></h5>-->
      <app-date [date]="report.created_at" [time]="true"></app-date>
    </div>
  </div>
  <!-- <div class="info" *ngIf="report">
    <div class="i">
      <span class="title">Variety</span>
      <h5>{{ report.type.name | titlecase}}</h5>
    </div>
  </div> -->

  <div class="images-wrapper" *ngIf="images.length">
    <span class="title">Images</span>
    <div class="images">
      <app-photo-gallery
        [images]="images"
        [editable]="false"
      ></app-photo-gallery>
    </div>
  </div>

  <div class="fields" *ngIf="fields.length">
    <div class="i">
      <div>
        <span class="title">Variety:</span>
        <h5>{{ report.type.name | titlecase}}</h5>
      </div>
    </div>
    <div *ngFor="let field of fields"
         [ngClass]="((field.field_types[0].name == 'notes' || field.value.length > 50) ? 'full': '') + ((field.field_types[0].exportable) ? ' exportable' : ' not-exportable') + ((field.prepped.length == 0) ? ' empty' : '')">
      <div *ngIf="field.prepped.length != 0">
        <span class="title">{{field.field_types[0].label}}:</span>
        <h5 class="value">{{field.prepped}}</h5>
      </div>
    </div>
  </div>


  <div class="actions">
    <button mat-flat-button (click)="go_back()">Go Back</button>
    <a class="edit-report" *ngIf="can_edit" mat-flat-button [routerLink]="'/reports/edit/' + report_id"
       [disabled]="!can_edit">Edit Report</a>
    <a *ngIf="['sales', 'admin'].includes('sales')" class="export" mat-flat-button color="primary"
       [routerLink]="'/reports/export/' + report_id">Send Report</a>
  </div>
</div>

<app-fullscreen-photoviewer
  *ngIf="fullscreenImage !== null"
  [images]="images"
  [initialIndex]="fullscreenImage"
  (close)="fullscreenImage=null"
></app-fullscreen-photoviewer>
