import {Component, Input, OnInit} from '@angular/core';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {

  @Input() date : any;
  @Input() time: boolean = false;
  @Input() exported: boolean = false;

  formatted_date : any = "";
  formatted_time : any = "";

  constructor() { }

  ngOnInit() {
    console.log(this.date);
    setTimeout(ev => {
      this.formatted_date = new DatePipe('en-US').transform(this.date, "M-d-yyyy");
      this.formatted_time = new DatePipe('en-US').transform(this.date, "h:mma");
    }, 2000)
  }

  format(d, format = 'date') {
    let date = d;
    try {
      if (format == 'exported') {
        date = new DatePipe('en-US').transform(this.date, "M/d/yyyy");
      }
      else {
        date = new DatePipe('en-US').transform(this.date, (format == 'date') ? "M-d-yyyy" : "h:mma");
      }

    } catch(e){
      if(format == 'time'){
        return '';
      }
      return d;
    }
    return date;
  }

}
