import {Component, Input, OnInit} from '@angular/core';
import {Location} from "@angular/common";
import {HttpInterceptorService} from "../../http-interceptor.service";
import {HistoryService} from "../../history.service";

interface NavigationStatus{
  enabled: boolean,
  link: string
}

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {

  @Input() navigation : NavigationStatus = {enabled: true, link: ''};

  constructor(private location : Location, public interceptor : HistoryService) {
    this.interceptor.tracker.subscribe(data => {
    });

  }

  go_back(ev) {
    return this.interceptor.go_back();
    if(this.navigation.link !== ""){

    } else {
      this.location.back();
    }
  }
  ngOnInit() {
  }

}
