<div class="fullscreen" [style.backgroundImage]="urlToStyle(images[index].src)">
  <div class="close-button" (click)="close.emit()">
    <mat-icon>cancel</mat-icon>
  </div>
  <div class="arrow prev" *ngIf="has_prev()" (click)="prev()">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </div>
  <div class="arrow next" *ngIf="has_next()" (click)="next()">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </div>
</div>
