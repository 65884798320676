<div [id]="'wrapper'" [class]="class_list" (click)="click_wrapper($event)" (contextmenu)="context_menu_click($event)" >
  <div [class]="progress_bar_class" [id]="'loading-bar'">
    <mat-progress-bar [attr.mode]="loading.mode" [attr.value]="loading.value"></mat-progress-bar>
  </div>
  <app-navigation></app-navigation>
  <app-messages></app-messages>
  <app-context-menu #contextMenu></app-context-menu>
  <router-outlet></router-outlet>

  <div class="version">
    v. {{version_number}}
    <span class="version-type" *ngIf="versionType">{{ versionType }}</span>
  </div>
</div>
