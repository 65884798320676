
  <div [class]="'mobile-navigation ' + ((hamburger.hamburger_open) ? 'open' : '')" #mobileNavigation>
    <div class="inner">
      <img [id]="'logo'" src="/assets/logo.png" alt="Logo"/>
      <mat-icon (click)="hamburger.toggle()">close</mat-icon>
      <div class="nav-links">
        <div class="nav-link" *ngFor="let link of links">
          <a [routerLink]="link.href" class="link" (click)="close($event)">
            <mat-icon>{{link.icon}}</mat-icon>
            {{link.title}}</a>
        </div>
      </div>
    </div>
    <div class="inner-2">
      <p>{{ user_email() }}</p>
      <a class="link" href="/logout">
        <mat-icon>logout</mat-icon>
        Logout</a>
    </div>
  </div>

