<app-sortable
  class="images"
  [disabled]="!sortable"
  (updated)="drop($event)"
>
  <app-photo
    *ngFor="let image of images; let i = index"
    [attr.data-id]="image.id"
    [src]="image.src"
    [progress]="image.progress"
    [editable]="editable"
    [downloadable]="downloadable"
    (delete)="deleteImage(image.id)"
    (fullscreen)="openFullscreen(image.id)"
  ></app-photo>
</app-sortable>

<app-fullscreen-photoviewer
  *ngIf="fullscreenIndex !== null"
  [images]="images"
  [initialIndex]="fullscreenIndex"
  (close)="fullscreenIndex = null"
></app-fullscreen-photoviewer>
