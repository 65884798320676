<div class="breadcrumbs-wrapper">
  <div class="segment">
    <a href="/dashboard"><mat-icon>home</mat-icon></a>
  </div>
  <div class="segment" *ngFor="let segment of segments">
    <a [routerLink]="[segment.link]">{{ segment.name }}</a>
  </div>
<!--   <div class="segment">
      <a href="/dashboard">Sent Sales Reports</a>
  </div>
  <div class="segment">
      <a href="/dashboard">Honey Crisp / Domex October 2, 2020, 4:50 pm</a>
  </div> -->
</div>
