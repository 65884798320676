import {Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {HamburgerService} from '../../hamburger.service';
import * as Hammer from 'hammerjs'
import {ApiService} from '../../api-service.service';
import {LoadingService} from '../../loading.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss']
})

export class MobileNavigationComponent implements OnInit, AfterViewInit {
  @ViewChild('mobileNavigation', {static: true}) divView: ElementRef;

  public show = true;

  public links = [
    {href: '/dashboard', title: 'Home', icon: 'home', logged_in: false},
    {href: '/reports', title: 'My Field Reports', icon: 'bar_chart', logged_in: true},
    {href: '/users', title: 'All Field Reports', icon: 'bar_chart', logged_in: true},
    {href: '/exports', title: 'Sent Sales Reports', icon: 'send', logged_in: true},
    {href: '/logout', title: 'Logout', icon: 'logout', logged_in: true},
  ];

  /* Links for sales members */
  public sales_links = [
    {href: '/reports/all', title: 'Home', icon: 'home', logged_in: false},
    {href: '/reports/all', title: 'All Field Reports', icon: 'bar_chart', logged_in: true},
    {href: '/exports', title: 'My Sent Reports', icon: 'send', logged_in: true},
    {href: '/logout', title: 'Logout', icon: 'logout', logged_in: true},
  ];

  constructor(public hamburger: HamburgerService, private api: ApiService, private router: Router ) {
  }

  ngAfterViewInit() {
    const hammer = new Hammer(this.divView.nativeElement);
    hammer.on('swipeleft', ev => {
      this.hamburger.toggle()
    });
    hammer.on('swiperight', ev => {
    });
  }

  ngOnInit() {

    this.home_checker();

  }

  /* Change the home page link depending on if the end user is logged in or not */
  home_checker() {
    this.refresh_links();
    this.router.events.subscribe(status => {
      this.refresh_links();
    });
  }

  refresh_links() {
    if (this.api.logged_in()) {
      this.find('Home').href = '/dashboard';
    } else {
      this.find('Home').href = '/dashboard';
    }

    if (localStorage.getItem('user_role') === 'sales') {
      this.find('Home').href = '/reports/all';
      this.links = this.sales_links;
    }
  }

  user_email(): string {
    let email = '';
    if (localStorage.getItem('email') !== 'undefined') {
      email = localStorage.getItem('email');
    }
    return email;
  }

  find(title: string) {
    let return_title: any = false;
    this.links.forEach(link => {
      if (link.title === title) {
        return_title = link;
        return false;
      }
    });
    return return_title;
  }

  close(event) {
    this.hamburger.hamburger_open = false;
  }
}
