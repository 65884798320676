import { Component, OnInit } from '@angular/core'
import {ApiService} from '../api-service.service'
import {ActivatedRoute} from '@angular/router'
import {HelperService} from '../helper.service'
import { BreadcrumbService } from '../breadcrumb.service'

@Component({
  selector: 'app-export-view',
  templateUrl: './export-view.component.html',
  styleUrls: ['./export-view.component.scss']
})
export class ExportViewComponent implements OnInit {

  public url = ''
  public export: any = { report : {type : {name: ''}}}
  public fields: any[] = []
  public images: any[] = []
  public content = ''

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    public helper: HelperService
  ) {
    route.params.forEach(val => this.url = val.id)
  }

  ngOnInit() {
    this.api.get_export(this.url).subscribe((data: any) => {
      this.breadcrumbService.setSegments([
        { name: 'Sent Sales Reports', link: '/exports' },
        { name: data.export.title || data.export.report.name, link: '/export/' + data.export.slug },
      ])

      console.log(data)
      this.export = data.export

      this.export.title = this.export.export_title
      this.content = data.content
      this.images = data.export.images
    })
  }

  prep_fields(fields) {
    /*for(let field of fields){
      if(field.field_options[0].name.includes('date')){
        field.value = new DatePipe('en-US').transform(field.value, "M-d-yyyy");
      }
    }*/
    for (let i = 0 ; i < fields.length ; i++) {
      fields[i] = fields[i].replace(/<\/?[^>]+(>|$)/g, '')

      if (fields[i].includes('Sub-variety')) {
        return fields[i].split(': ')[1]
      }
    }
    return
  }

  logged_in() {
    return (localStorage.getItem('user_id'))
  }
}
