import {
	Component,
	ElementRef,
	HostListener,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren,
} from "@angular/core";
import { ApiService } from "../api-service.service";
import { WrapperClassService } from "../wrapper-class.service";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import * as Hammer from "hammerjs";
import {
	animate,
	state,
	style,
	transition,
	trigger,
} from "@angular/animations";
import { MatDialog, MatSelect } from "@angular/material";
import { DialogComponent } from "../dialog/dialog.component";
import { MessagesService, MessageType } from "../messages.service";
import { HistoryService } from "../history.service";
import { HelperService } from "../helper.service";
import { Location } from "@angular/common";
import { BreadcrumbService } from "../breadcrumb.service";
import { combineLatest } from "rxjs";

export interface Selection {
	link: string;
	text: string;
	editable: boolean;
	display: string;
	type: string;
	value: number;
	count?: number;
	animate_state?: number | string;
	panning?: number | boolean;
	clickable?: boolean;
	superText?: string;
}

export interface Pagination {
	current_page: number;
	first_page_url?: string;
	from: number;
	last_page: number | null;
	last_page_url?: string | null;
	next_page_url?: string | null;
	path: string;
	per_page: number;
	prev_page_url?: string | null;
	total: number;
	to: number;
}

export interface SearchOptions {
	searchEnabled: boolean;
	searchCallback: (phrase: string) => void | null;
	clearCallback: () => void;
}

@Component({
	selector: "app-reports",
	templateUrl: "./reports.component.html",
	styleUrls: ["./reports.component.scss"],
	animations: [
		trigger("toggleBack", [
			state(
				"out",
				style({
					transform: "translateX(200%)",
				})
			),
			state(
				"back",
				style({
					transform: "translateX(0)",
				})
			),
			state(
				"panning",
				style({
					transform: "translateX({{panning}}px)",
				}),
				{ params: { panning: 0 } }
			),
			transition("panning => back", animate(".1s")),
			transition("out => back", animate(".25s")),
			transition("panning => out", animate(".5s")),
		]),
	],
})
export class ReportsComponent implements OnInit {
	reports: any[] = [{}, {}, {}, {}];
	loading = true;
	@ViewChildren("rep") report_elements: QueryList<ElementRef>;
	@ViewChild("sortby", { static: true }) sortby: MatSelect;

	public selection_type = "User";

	public selections: Selection[] = [];

	public url = "routes";
	private params: Params;

	searchTerms = "";
	public paginated = false;

	public pagination: Pagination = {
		current_page: 0,
		from: 1,
		to: 10,
		total: 17,
		last_page: 2,
		next_page_url: "",
		last_page_url: "",
		first_page_url: "",
		path: "",
		prev_page_url: null,
		per_page: 10,
	};
	public nav_options = ["Recent", "All", "Users", "Varieties"];
	private nav_options_details = {
		Recent: { url: "/reports" },
		All: { url: "/reports/all" },
		Varieties: { url: "/varieties" },
		Users: { url: "/users" },
	};

	public sent_request = false;
	public is_exports = false;

	public max = 0;

	public search_options: SearchOptions = {
		searchEnabled: false,
		searchCallback: (phrase: string) => {
			return;
		},
		clearCallback: () => {
			return;
		},
	};

	constructor(
		private api: ApiService,
		private message: MessagesService,
		private cls: WrapperClassService,
		private router: Router,
		private dialog: MatDialog,
		private activated_route: ActivatedRoute,
		private back: HistoryService,
		public helper: HelperService,
		private location: Location,
		private breadcrumbService: BreadcrumbService
	) {
		this.cls.class_list = "reports-page";
		router.events.subscribe((ev) => {
			if (ev instanceof NavigationEnd) {
				this.url = ev.url;
			}
		});

		this.activated_route.params.subscribe((params) => {
			this.params = params;

			if (params.page) {
				this.pagination.current_page = params.page;
			}
		});
	}

	ngOnInit() {
		this.sent_request = false;
		let page_number = this.params.page;
		if (typeof page_number === "undefined") {
			page_number = 1;
			this.pagination.current_page = page_number;
		}

		if (this.url.includes("varieties")) {
			this.breadcrumbService.setSegments([
				{ name: "Varieties", link: "/varieties" },
			]);

			this.selection_type = "Varieties";

			this.api.list_types().subscribe((types) => {
				this.sent_request = true;
				types.forEach((type) => {
					const selection: Selection = {
						text: type.name,
						editable: false,
						link: "/variety/" + type.id,
						display: "block",
						type: "type",
						value: type.id,
						clickable: true,
						count: type.reports_count,
					};
					this.selections.push(selection);
				});
			});
		} else if (this.url.includes("exports")) {
			this.breadcrumbService.setSegments([
				{ name: "Sent Sales Reports", link: "/exports" },
			]);
			this.handle_exports();
		} else if (this.url.includes("variety")) {
			this.selection_type = "Varieties";
			const type_id = parseInt((this.params as any).id, 10);

			combineLatest([
				this.api.list_types(),
				this.api.list_sub_varieties(type_id),
			]).subscribe(([varieties, subVarieties]) => {
				this.sent_request = true;

				const currentVariety = varieties.find((v) => v.id === type_id);
				this.breadcrumbService.setSegments([
					{ name: "Varieties", link: "/varieties" },
					{ name: currentVariety.name, link: "/variety/" + type_id },
				]);

				if (subVarieties.length === 0) {
					// Load in reports for type
					this.router.navigate(["/reports_by_type/", type_id]);
				} else {
					// load in selections
					subVarieties.forEach((sub: any) => {
						const query = {
							field_name: "variety",
							field_value: sub.value,
							type_id,
						};
						const query_string = btoa(JSON.stringify(query));
						const selection = {
							text: sub.value,
							editable: false,
							link: "/reports_by_fields/" + query_string,
							display: "grid",
							type: "sub_variety",
							value: sub.id,
							count: sub.reports_count,
						};
						this.selections.push(selection);
					});
				}
			});
		} else if (this.url.includes("reports_by_fields")) {
			this.handle_reports_by_fields();
		} else if (this.url.includes("users")) {
			this.breadcrumbService.setSegments([
				{ name: "User Reports", link: "/users" },
			]);

			this.selection_type = "Users";
			const user_id = (this.params as any).id;
			this.api.list_users("reporter|admin").subscribe((users: any) => {
				this.sent_request = true;
				users.forEach((user: any) => {
					const selection: Selection = {
						text: user.name,
						link: `/reports_by_user/${user.id}`,
						display: "grid",
						editable: false,
						type: "user",
						value: user.id,
						count: user.reports_count,
					};

					this.selections.push(selection);
				});
			});
		} else if (this.url.includes("reports_by_user")) {
			this.handle_reports_by_user();
		} else if (this.url.includes("reports_by_type")) {
			this.selection_type = "Varieties";
			const type_id = (this.params as any).id;
			this.api.list_reports_by_type(type_id).subscribe((reports: any) => {
				this.sent_request = true;
				this.handle_reports(reports);
			});
		} else if (this.url.includes("reports/recent")) {
			// Pretty sure this doesn't do anything
			// The recent selector just goes to /reports
			this.selection_type = "Recent";
			this.api.all_recent_reports().subscribe((reports: any) => {
				this.paginated = true;
				this.sent_request = true;
				this.handle_reports(reports);
			});
		} else if (this.url.includes("reports/all")) {
			this.handle_all_reports();
		} else if (this.url.includes("reports")) {
			this.handle_recent_reports();
		}

		if (typeof this.sortby !== "undefined") {
			this.sortby.value = this.selection_type;
		}
	}

	update_navigation(ev) {
		const val = ev.value;
		this.router.navigate([this.nav_options_details[val].url]);
	}

	selection_click(selection) {
		if (selection.clickable) {
			if (selection.type === "report") {
				this.router.navigate(["reports", selection.value]);
			} else {
				this.router.navigate([selection.link]);
			}
		}
	}

	animation_start(event, s) {
		s.clickable = false;
	}

	animation_done(event, s) {
		s.clickable = true;
	}

	@HostListener("window:scroll", ["$event"])
	on_scroll(event) {
		// console.log(event, 'scroll');

		const pos =
			(document.documentElement.scrollTop || document.body.scrollTop) +
			document.documentElement.offsetHeight;
		const max = document.documentElement.scrollHeight;
		if (pos === max) {
			console.log("rock bottom");
		}
	}

	paginate(event) {
		console.log(event, this.pagination, this.url);
		const new_page = event.pageIndex + 1;
		this.pagination.current_page = new_page;

		if (this.url.includes("exports")) {
			this.location.go(`/exports/${new_page}`);
			this.handle_exports();
		} else if (this.url.includes("reports/all")) {
			// this.location.go(`/reports/all/${new_page}`)
			this.handle_all_reports();
		} else if (this.url.includes("reports_by_user")) {
			this.location.go(`/reports_by_user/${this.params.id}/${new_page}`);
			this.handle_reports_by_user();
		} else if (this.url.includes("reports_by_fields")) {
			this.location.go(`/reports_by_user/${this.params.base64}/${new_page}`);
			this.handle_reports_by_fields();
		} else if (this.url.startsWith("/reports")) {
			this.handle_recent_reports();
		}
	}

	// /reports/all
	handle_all_reports() {
		this.breadcrumbService.setSegments([
			{ name: "All Reports", link: "/reports/all" },
		]);

		this.selections = [];
		this.selection_type = "All";

		this.search_options.searchEnabled = true;

		this.search_options.searchCallback = (phrase) => {
			console.log(phrase + " The phrase!");

			this.searchTerms = phrase;

			this.paginated = true;
			this.api
				.all_recent_reports(this.pagination.current_page, phrase)
				.subscribe((reports: any) => {
					this.sent_request = true;
					this.pagination = reports;
					this.selections = [];
					this.handle_reports(reports.data);
				});
		};

		this.search_options.clearCallback = () => {
			this.searchTerms = "";
			this.selections = [];
			this.api
				.all_recent_reports(this.pagination.current_page)
				.subscribe((reports: any) => {
					this.paginated = true;
					this.sent_request = true;

					this.pagination = reports;

					reports = reports.data;

					this.handle_reports(reports);
				});
		};

		this.api
			.all_recent_reports(this.pagination.current_page, this.searchTerms)
			.subscribe((reports: any) => {
				this.paginated = true;
				this.sent_request = true;

				this.pagination = reports;

				reports = reports.data;

				this.handle_reports(reports);
			});
	}

	handle_recent_reports() {
		this.selection_type = "Recent";

		this.breadcrumbService.setSegments([
			{ name: "Recent Reports", link: "/reports" },
		]);

		this.api
			.list_reports_by_user(
				parseInt(localStorage.getItem("user_id"), 10),
				this.pagination.current_page
			)
			.subscribe((reports: any) => {
				this.selections = [];
				this.sent_request = true;
				this.pagination = reports;
				reports = reports.data;
				this.handle_reports(reports);
				this.paginated = true;
			});
	}

	handle_reports_by_user() {
		this.selections = [];
		this.selection_type = "Users";
		const user_id = (this.params as any).id;

		this.api
			.list_reports_by_user(user_id, this.pagination.current_page)
			.subscribe((reports) => {
				const user = reports.data?.[0].user;
				this.breadcrumbService.setSegments([
					{ name: "User Reports", link: "/users" },
					{ name: user.name, link: "/reports_by_user/" + user.id },
				]);

				this.paginated = true;
				this.sent_request = true;
				this.pagination = reports;
				this.handle_reports(reports.data);
			});
	}

	handle_reports_by_fields() {
		this.selections = [];
		this.selection_type = "Varieties";
		const { base64 } = this.params;
		const params = JSON.parse(atob(base64));
		const subVarietyName = params.field_value;
		console.log({ params });

		combineLatest([
			this.api.list_types(),
			this.api.list_reports_by_field(params),
		]).subscribe(([varieties, reports]) => {
			this.sent_request = true;

			// Somebody kill me
			const currentVariety = varieties.find((v) => v.id === params.type_id);
			this.breadcrumbService.setSegments([
				{ name: "Varieties", link: "/varieties" },
				{ name: currentVariety.name, link: "/variety/" + params.type_id },
				{ name: subVarietyName, link: "/reports_by_fields/" + base64 },
			]);

			this.pagination = reports;
			this.handle_reports(reports.data);
			this.paginated = true;
		});
	}

	handle_exports() {
		this.selections = [];
		this.paginated = true;
		this.selection_type = "none";
		this.is_exports = true;
		this.search_options.searchEnabled = true;

		this.search_options.searchCallback = (phrase: any) => {
			this.api.search(phrase).subscribe((data: any) => {
				const selections: Selection[] = [];
				data.forEach((exp) => {
					const selection: Selection = {
						text: exp.title,
						editable: true,
						link: "/export/" + exp.slug,
						display: "block",
						type: "export",
						value: exp.id,
						clickable: true,
						count: null,
					};
					selections.push(selection);
				});
				this.selections = selections;
				// this.handle_reports(selections);
			});
		};

		this.search_options.clearCallback = () => {
			this.selections = [];
			this.api
				.get_exports(this.pagination.current_page)
				.subscribe((exports: any) => {
					this.sent_request = true;
					const pagination = exports;
					// delete pagination.data;
					this.pagination = pagination;
					exports = exports.data;
					exports.forEach((exp) => {
						const selection: Selection = {
							text: exp.title,
							editable: true,
							link: "/export/" + exp.slug,
							display: "block",
							type: "export",
							value: exp.id,
							clickable: true,
							count: null,
						};
						this.selections.push(selection);
					});
					// this.handle_reports(reports);
				});
		};

		this.api
			.get_exports(this.pagination.current_page)
			.subscribe((exports: any) => {
				this.sent_request = true;
				const pagination = exports;
				// delete pagination.data;
				this.pagination = pagination;
				exports = exports.data;
				exports.forEach((exp) => {
					const selection: Selection = {
						text: exp.title,
						editable: true,
						link: "/export/" + exp.slug,
						display: "block",
						type: "export",
						value: exp.id,
						clickable: true,
						count: null,
					};
					this.selections.push(selection);
				});
				// this.handle_reports(reports);
			});
	}

	click_edit(ev, report_id, selection: Selection) {
		if (selection.type !== "export") {
			this.router.navigate(["reports", "edit", report_id]);
		} else {
			this.router.navigate(["reports", "export", report_id, report_id]);
		}
	}

	is_report_editable(report) {
		if (report.user_id === localStorage.getItem("user_id")) {
			return true;
		} else {
			return false;
		}
	}

	handle_reports(reports) {
		this.loading = false;
		console.log("reports", reports);

		if (reports.length === 0) {
			this.message.add_message("No reports found.", MessageType.Normal);
		}
		reports.forEach((report: any) => {
			// console.log(report);
			const selection: Selection = {
				text: report.name,
				editable: this.is_report_editable(report),
				link: `/reports/${report.id}`,
				value: report.id,
				display: "grid",
				type: "report",
				animate_state: "",
				panning: 0,
			};
			this.selections.push(selection);
		});

		this.report_elements.changes.subscribe((things: any) => {
			this.report_elements.forEach((div: ElementRef, i) => {
				this.selections[i].animate_state = "";
				(div as any).report = this.selections[i];
				const hammer = new Hammer(div.nativeElement);

				hammer.add(
					new Hammer.Pan({
						direction: Hammer.DIRECTION_HORIZONTAL,
						threshold: 0,
					})
				);

				hammer.on("pan", (ev) => {
					this.on_drag(ev, div);
				});
				hammer.on("panend", (ev) => this.on_pan_end(ev, div));
			});
		});
	}

	on_drag(ev, div) {
		if (ev.deltaX > 0) {
			div.report.animate_state = "panning";
			div.report.panning = ev.deltaX;
		}
	}

	on_pan_end(ev, div) {
		const element = (div as any).nativeElement.querySelector(".foreground");
		if (ev.deltaX > 150 && div.report.animate_state !== "out") {
			div.report.animate_state = "out";
			const dialog = this.dialog.open(DialogComponent, {
				// height: '300px',
				width: "80%",
				data: {
					title: "Delete field report?",
					message: "Deleted reports will be recoverable for 30 days.",
					report: div.report,
					div,
					delete_callback: this.delete_report.bind(this),
					cancel_callback: this.cancel_report_delete.bind(this),
					confirm_button: "Yes, delete report.",
				},
			});
			dialog.afterClosed().subscribe((dialogResult) => {
				// console.log(ev);
				if (dialogResult !== "delete") {
					div.report.animate_state = "back";
				}
			});
		} else {
			div.report.animate_state = "back";
		}
	}

	cancel_report_delete(report, event, dialog_ref, div) {
		div.report.animate_state = "back";
	}

	delete_report(report, event, dialog_ref) {
		// console.log(report, event);
		this.api.delete_report(report.value).subscribe((ev) => {
			this.reports.forEach((r, i) => {
				if (r.id === report.id) {
					// this.reports.splice(i, 1);
					report.display = "none";
				}
			});
		});
	}

	back_button_click(ev) {
		this.back.go_back();
	}
}
