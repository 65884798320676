import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HamburgerService {
  public hamburger_open : boolean = false;
  constructor() { }

  public toggle() {
    this.hamburger_open = !this.hamburger_open;
  }
}
