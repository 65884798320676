import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-report-link',
  templateUrl: './report-link.component.html',
  styleUrls: ['./report-link.component.scss']
})
export class ReportLinkComponent implements OnInit {

  @Input()
  report_info: any;

  constructor(private router : Router) {
  }

  ngOnInit() {
  }

  click_edit(ev, report_info) {
    this.router.navigate(['reports', 'edit', report_info.id]);
  }

}
